import moment from 'moment';
import 'moment/locale/ar';

window.moment = moment;

moment.locale('en', {
  calendar: {
    lastDay: '[Yesterday] LT',
    sameDay: 'LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: 'dddd LT',
    nextWeek: 'dddd [at] LT',
    sameElse: function (now) {
      if (this.isSame(now, 'year')) {
        return 'DD/MM LT';
      } else {
        return 'L';
      }
    }
  }
});

moment.locale('ar', {
  calendar: {
    lastDay: '[أمس] LT',
    sameDay: 'LT',
    nextDay: '[الغد عند] LT',
    lastWeek: 'dddd LT',
    nextWeek: 'dddd [عند] LT',
    sameElse: function (now) {
      if (this.isSame(now, 'year')) {
        return 'DD/MM LT';
      } else {
        return 'L';
      }
    }
  }
});
