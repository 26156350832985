import { Locale, PageDir } from '../typings';
import I18n from 'i18n-js';
import * as en from '../locales/en.json';
import * as ar from '../locales/ar.json';
import moment from 'moment';

export default class LocalizationService {
  constructor(private locale: Locale = 'en') {
    I18n.defaultLocale = 'en';
    I18n.locale = locale;
    I18n.translations = {
      en,
      ar
    };

    moment.locale(locale);
  }

  static initialize(locale?: Locale): LocalizationService {
    return new LocalizationService(locale);
  }

  setLocale(locale: Locale): LocalizationService {
    this.locale = locale;

    return this;
  }

  getLocale(): Locale {
    return this.locale;
  }

  isRtl(): boolean {
    return this.getPageDir() === 'rtl';
  }

  getPageDir(): PageDir {
    return LocalizationService.getPageDirFromLocale(this.locale);
  }

  static getPageDirFromLocale(locale: Locale): PageDir {
    return {
      en: 'ltr',
      ar: 'rtl'
    }[locale] as PageDir;
  }
}
