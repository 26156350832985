import {
  ChatChannel,
  ChatService,
  ChatUser,
  useChannelsData
} from 'bulbul-chat';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { MdArchive, MdReplay, MdUnarchive } from 'react-icons/md';
import { RiMore2Line, RiImageFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import ChannelService from '../services/ChannelService';
import { capitalize } from '../utils';
import I18n from 'i18n-js';

type ChannelsListingViewPropsType = {
  chatService: ChatService;
  channelUrl: (channelId: string) => string;
};

const ChannelListItemView = ({
  channel,
  channelUrl,
  onArchive,
  onUnArchive,
  currentUser
}: {
  channel: ChatChannel;
  channelUrl: (channelId: string) => string;
  onArchive: () => void;
  onUnArchive: () => void;
  currentUser: ChatUser;
}) => {
  const { name, image } = ChannelService.nameAndImage(channel);

  return (
    <NavLink
      key={channel.id}
      activeClassName='bg-coolGray-100'
      className='p-2 flex space-s-4 rounded-lg bg-coolGray-50'
      to={channelUrl(channel.externalId)}
      title={JSON.stringify(channel.metadata)}
    >
      <div className='relative flex-shrink-0'>
        {!!channel.unreadMessageCount && (
          <span className='absolute w-4 h-4 rounded-full bg-emerald-500 text-white text-center align-middle text-xs'>
            {channel.unreadMessageCount}
          </span>
        )}
        <img
          src={channel.photoUrl || image}
          className='w-10 h-10 object-cover rounded-full'
          alt={name}
        />
        <img
          src={image}
          className='absolute top-5 start-5 w-6 h-6 object-cover border-2 border-white rounded-full'
          alt={name}
        />
      </div>
      <div className='flex-1 overflow-hidden'>
        <div className='flex items-center justify-between space-s-8'>
          <h2
            title={channel.name}
            className='mb-0.5 text-sm leading-5 text-coolGray-700 font-semibold truncate'
          >
            {channel.name}{' '}
            {channel.isBlockingMe && `(${I18n.t('blocking_me')})`}
          </h2>
          <span className='text-xs text-coolGray-400'>
            {moment(new Date(Date.parse(channel.lastActivityAt))).calendar()}
          </span>
        </div>
        <h2
          title={name}
          className='text-coolGray-700 leading-4 text-xs mb-1 truncate'
        >
          {name}
        </h2>
        {channel.lastMessage &&
          (() => {
            const isMe =
              currentUser &&
              channel.lastMessage.senderId?.toString() ===
                currentUser.id.toString();
            if (
              ['image', 'video', 'document', 'file', 'audio'].includes(
                channel.lastMessage.messageType
              )
            ) {
              return (
                <p
                  className={`text-xs flex items-center space-s-1.5 ${
                    channel.unreadMessageCount
                      ? 'font-medium text-coolGray-900'
                      : 'font normal text-coolGray-400'
                  }`}
                >
                  <RiImageFill className='w-3 h-3' />
                  <span className='truncate'>
                    {capitalize(channel.lastMessage.messageType)}{' '}
                    {isMe ? 'Sent' : 'Received'}
                  </span>
                </p>
              );
            } else {
              return (
                <p
                  className={`text-xs flex items-center space-s-1.5 ${
                    channel.unreadMessageCount
                      ? 'font-medium text-coolGray-900'
                      : 'font normal text-coolGray-400'
                  }`}
                >
                  {!!channel.unreadMessageCount && (
                    <span className='w-2 h-2 rounded-full bg-emerald-500' />
                  )}
                  <span className='truncate'>
                    {channel.lastMessage.content}
                  </span>
                </p>
              );
            }
          })()}
      </div>
      {/*<div className="p-2 pb-4 flex flex-row justify-start space-s-6 bg-white border border-solid border-b-0 border-gray-200">*/}
      {/*  */}
      {/*</div>*/}
    </NavLink>
  );
};

const ChannelsListingView = ({
  chatService,
  channelUrl
}: ChannelsListingViewPropsType) => {
  const [state, loadEarlier] = useChannelsData(chatService);
  const [currentUser, setCurrentUser] = React.useState<ChatUser>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  useEffect(() => {
    chatService
      .getCurrentUser()
      .then((user: ChatUser) => setCurrentUser(user))
      .catch(() => {
        setErrorMessage('Unauthorized');
      });
  }, [chatService]);

  if (errorMessage) return <div>{errorMessage}</div>;
  if (!currentUser) return <div>{I18n.t('loading')}</div>;

  return (
    <Fragment>
      <div className='flex p-4 mb-4 items-center justify-between'>
        <h1 className='text-2xl text-coolGray-900 font-medium'>
          {I18n.t('all_messages')}
        </h1>
        <RiMore2Line size={24} className='text-coolGray-400' />
      </div>
      {state.errorMessage && (
        <div>
          {state.errorMessage}{' '}
          <button onClick={() => loadEarlier()}>Retry</button>
        </div>
      )}
      {state.maxRetryAttemptsReached && (
        <span>
          Connection lost...{' '}
          <button onClick={() => null}>
            <MdReplay />
          </button>
        </span>
      )}
      <div className='p-4 flex flex-col space-y-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 overflow-y-scroll scrollbar-thumb-rounded'>
        {Object.values(state.channels).map((channel) => {
          return (
            <ChannelListItemView
              key={channel.id}
              channel={channel}
              channelUrl={channelUrl}
              onArchive={() => chatService.archiveChannel(channel.id)}
              onUnArchive={() => chatService.unArchiveChannel(channel.id)}
              currentUser={currentUser}
            />
          );
        })}
      </div>
      {state.loadingEarlier && (
        <div>
          <span>{I18n.t('loading')}</span>
        </div>
      )}
      {!state.loadingEarlier && state.hasMoreEarlier && (
        <div>
          <button onClick={() => loadEarlier()}>Load Earlier</button>
        </div>
      )}
    </Fragment>
  );
};

export default ChannelsListingView;
