import React, { useContext } from 'react';
import moment from 'moment';
import I18n from 'i18n-js';
import AppContext from '../contexts/AppContext';
import { ChatMessage } from 'bulbul-chat';

function DateSeparator({
  message,
  previousMessage
}: {
  message: ChatMessage;
  previousMessage?: ChatMessage;
}) {
  const { localization } = useContext(AppContext);
  let dateSeparator = null;

  if (
    !previousMessage ||
    (previousMessage &&
      !moment(message.createdAt).isSame(previousMessage.createdAt, 'day'))
  ) {
    dateSeparator = (
      <div className='relative'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-10/12 mx-auto border-t border-gray-300' />
        </div>
        <div className='relative flex justify-center'>
          <span className='px-2 bg-white text-sm text-gray-500'>
            {moment(new Date(Date.parse(message.createdAt as string))).calendar(
              {
                sameDay: `[${I18n.t('today')}]`,
                lastDay: `[${I18n.t('yesterday')}]`,
                sameElse: localization.isRtl() ? 'YY/M/D' : 'M/D/YY'
              }
            )}
          </span>
        </div>
      </div>
    );
  }

  return dateSeparator;
}

export default DateSeparator;
