import { ChatService, ChatUser } from 'bulbul-chat';
import React, { FC, Fragment, useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Redirect
} from 'react-router-dom';

import packageJson from '../package.json';
import ChannelView from './Components/ChannelView';
import ChannelsListingView from './Components/ChannelsListingView';
import { useQuery } from './utils';
import AppContext, { AppContextType } from './contexts/AppContext';

import './config/moment';
import './styles/index.css';
import { Locale } from './typings';
import { LOCALES } from './config/app';
import LocalizationService from './services/LocalizationService';
import I18n from 'i18n-js';

interface AppProps {
  userToken?: string;
  basename?: string;
  endpoint?: string;
  locale?: Locale;
}

const App: FC<AppProps> = ({
  userToken,
  basename,
  endpoint,
  locale = 'en'
}) => {
  userToken = useQuery().get('token') || userToken || '';
  const queryStringLocale = useQuery().get('locale') as Locale;
  if (LOCALES.includes(queryStringLocale)) {
    locale = queryStringLocale;
  }
  const localization = LocalizationService.initialize(locale);
  const appProps: AppContextType = { userToken, localization };

  return (
    <div dir={localization.getPageDir()} className='h-full'>
      <AppContext.Provider value={appProps}>
        <Router basename={basename}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/chat' />
            </Route>
            <Route path='/chat'>
              <ChannelsPage endpoint={endpoint} />
            </Route>
            <Route path='/sdk/version'>
              {packageJson.dependencies['bulbul-chat']}
            </Route>
          </Switch>
        </Router>
      </AppContext.Provider>
    </div>
  );
};

function ChannelsPage({ endpoint }: { endpoint?: string }) {
  const [currentUser, setCurrentUser] = React.useState<ChatUser>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [chatService, setChatService] = React.useState<ChatService>();
  const match = useRouteMatch();
  const { userToken } = useContext(AppContext);
  const chatUIMode = useQuery().get('chatUIMode') || 'inbox';

  const showChannelsList = chatUIMode !== 'chatbox';
  useEffect(() => {
    if (!userToken) {
      setErrorMessage('Missing Token');
    } else {
      const cs = ChatService.getService(userToken, endpoint);
      setChatService(cs);
      cs.getCurrentUser()
        .then((user: ChatUser) => {
          setCurrentUser(user);
          cs.start();
        })
        .catch(() => {
          setErrorMessage('Unauthorized');
        });
    }
  }, [userToken]);

  if (errorMessage) return <div>{errorMessage}</div>;
  if (!currentUser) return <div>{I18n.t('loading')}</div>;
  if (!chatService) return <div>{I18n.t('loading')}</div>;
  return (
    <Fragment>
      {chatService && (
        <div className='flex h-full sm:space-s-4'>
          {showChannelsList && (
            <div
              className={`w-full sm:w-1/3 rounded-lg bg-white flex-col ${
                match.isExact ? 'flex' : 'hidden sm:flex'
              }`}
            >
              <ChannelsListingView
                chatService={chatService}
                channelUrl={(channelId) =>
                  `/chat/${channelId}${window.location.search}`
                }
              />
            </div>
          )}

          <div className='w-2/3 rounded-lg bg-white h-full flex-1 flex flex-col overflow-hidden'>
            <Switch>
              <Route path='/chat/:channelId'>
                <ChannelPage />
              </Route>
              <Route path={match.path} />
            </Switch>
          </div>
        </div>
      )}
    </Fragment>
  );
}

function ChannelPage() {
  const { channelId } = useParams<{ channelId: string }>();
  return <ChannelView channelId={channelId} />;
}

export default App;
