import { ChatChannel } from 'bulbul-chat';

export default class ChannelService {
  static nameAndImage(channel: ChatChannel): { name: string; image: string } {
    let name = channel.name;
    let image = channel.photoUrl;

    if (channel.channelType === 'direct' && channel.channelMemberships) {
      const channelMembership = channel.channelMemberships.find(
        (channelMembership) =>
          channelMembership.user && channelMembership.user.externalId !== '1'
      );
      if (channelMembership) {
        const user = channelMembership.user;
        name = user.name;
        image = user.photoUrl;
      }
    }

    return { name, image };
  }
}
