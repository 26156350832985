import React from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { ChatMessage } from 'bulbul-chat';
import { Sender } from '../typings';

function Message({
  message,
  sender,
  isMe,
  useFullName
}: {
  message: ChatMessage;
  sender: Sender;
  isMe: boolean;
  useFullName?: boolean;
}) {
  const renderAttachment = () => {
    if (message.messageType === 'plaintext') {
      return null;
    } else if (
      ['image', 'video', 'document', 'file'].includes(message.messageType)
    ) {
      return (
        <a
          href={message.attachment?.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='w-96 h-96'
            src={message.attachment?.thumbnail}
            alt=''
          />
        </a>
      );
    } else if (message.messageType === 'audio') {
      return (
        <audio controls className='w-52 sm:w-72'>
          <source
            className='w-full'
            src={message.attachment?.url}
            type={message.attachment?.contentType}
          />
        </audio>
      );
    }
  };

  return (
    <div className={`p-4 flex ${isMe ? 'justify-end' : ''}`}>
      <div className='flex space-s-4 overflow-hidden'>
        {!isMe && (
          <img
            src={sender.image}
            className='w-10 h-10 object-cover rounded-full'
            alt={sender.name}
          />
        )}
        <div
          className={`max-w-xl rounded-lg p-3 overflow-hidden ${
            isMe
              ? 'items-end bg-white border border-coolGray-200'
              : 'bg-coolGray-50'
          }`}
        >
          <div className='flex items-center justify-between space-s-8 mb-1.5'>
            <h2 className='text-sm leading-5 text-coolGray-900 font-semibold truncate'>
              {isMe && !useFullName ? I18n.t('me') : sender.name}
            </h2>
            {message.createdAt && (
              <small className='text-sm text-coolGray-400 leading-5'>
                {moment(new Date(Date.parse(message.createdAt))).format('LT')}
              </small>
            )}
          </div>
          <div>{renderAttachment()}</div>
          <p dir='auto' className='text-base leading-5 text-coolGray-700'>
            {message.content}
          </p>

          {/*<small className='text-gray-600'>{getMessageStatus(message)}</small>*/}
        </div>
      </div>
    </div>
  );
}

export default Message;
