import { useEffect, useState } from 'react';

const useRecorder = (): {
  audio: any;
  isRecording: boolean;
  startRecording: () => any;
  stopRecording: () => any;
} => {
  const [audio, setAudio] = useState<{ mimeType: string; url: string } | null>(
    null
  );
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e: any) => {
      setAudio({
        mimeType: recorder.mimeType,
        url: URL.createObjectURL(e.data)
      });
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [recorder, isRecording]);

  const startRecording = async () => {
    if (recorder === null) {
      const recorder = await requestRecorder();
      setIsRecording(true);
      setRecorder(recorder);
    } else {
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return { audio, isRecording, startRecording, stopRecording };
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
