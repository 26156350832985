import {
  ChatUser,
  ChatService,
  useChannelData,
  ChatMessage,
  IActingType
} from 'bulbul-chat';
import moment from 'moment';
import React, { useEffect, MouseEvent, useContext, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { MdCancel, MdCheck, MdMic, MdReplay, MdSend } from 'react-icons/md';
import AppContext from '../contexts/AppContext';

import useRecorder from './useRecorder';
import ChannelService from '../services/ChannelService';
import { RiArrowLeftLine, RiArrowRightLine, RiMore2Line } from 'react-icons/ri';
import { ImAttachment } from 'react-icons/im';
import I18n from 'i18n-js';
import { Sender } from '../typings';
import Message from './Message';
import DateSeparator from './DateSeparator';

type PropsType = {
  channelId: string;
};

const MessageView = ({
  message,
  sender,
  isMe,
  onRetry,
  lastReadMessageId,
  lastRecievedMessageId
}: {
  message: ChatMessage;
  sender: Sender;
  isMe: boolean;
  onRetry: (message: ChatMessage) => void;
  lastReadMessageId: number;
  lastRecievedMessageId: number;
}) => {
  function getMessageStatus(message: ChatMessage): React.ReactNode {
    if (message.isQueued) {
      return message.isRetriable ? (
        <button className='flex' onClick={() => onRetry(message)}>
          failed to send click to retry <MdReplay />
        </button>
      ) : (
        'sending...'
      );
    } else if (parseInt(message.id || '0', 10) <= lastReadMessageId) {
      return 'read';
    } else if (parseInt(message.id || '0', 10) <= lastRecievedMessageId) {
      return 'recieved';
    }
    return 'sent';
  }

  return <Message message={message} sender={sender} isMe={isMe} />;
};

const DropDownButton = ({
  title,
  onClick
}: {
  title: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className='block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-start'
      role='menuitem'
    >
      {title}
    </button>
  );
};

const Timer = () => {
  const [seconds, setSeconds] = React.useState(0);
  React.useEffect(() => {
    const handle = setTimeout(() => {
      setSeconds(seconds + 1);
    }, 1000);

    return () => clearTimeout(handle);
  });
  const displayMinutes = Math.round(seconds / 60)
    .toString()
    .padStart(2, '0');
  const displaySeconds = Math.round(seconds % 60)
    .toString()
    .padStart(2, '0');
  return (
    <span>
      {displayMinutes}:{displaySeconds}
    </span>
  );
};

function getActingVerb(actingType?: IActingType) {
  switch (actingType) {
    case 'text':
      return 'typing';
    case 'voice':
      return 'recording voice';
    case 'video':
      return 'recording video';
  }
  return null;
}

const ChannelView = (props: PropsType) => {
  const { userToken, localization } = useContext(AppContext);
  const chatService = ChatService.getService(
    userToken,
    process.env.REACT_APP_BULBUL_CHAT_ENDPOINT
  );
  const [state, loadEarlier, loadLater] = useChannelData(
    props.channelId,
    chatService
  );
  const [textInput, setTextInput] = React.useState<string>('');
  const [scrollBottom, setScrollBottom] = React.useState<boolean>(true);
  const [scrolledToUnread, setScrolledToUnread] =
    React.useState<boolean>(false);
  const bottomDivRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [currentUser, setCurrentUser] = React.useState<ChatUser>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [attachmentDropDownVisible, setAttachmentDropDownVisible] =
    React.useState(false);
  const [channelOptionsDropDownVisible, setChannelOptionsDropDownVisible] =
    React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const newUnreadMessagesRef = React.useRef<HTMLInputElement>(null);
  const { audio, isRecording, startRecording, stopRecording } = useRecorder();
  const [isRecordingCancelled, setRecordingCancelled] = React.useState(false);

  useEffect(() => {
    if (isRecordingCancelled) {
      setRecordingCancelled(false);
    } else if (audio && audio.url) {
      handleSubmit(audio);
    }
  }, [audio]);

  const handleSubmit = (audio?: { mimeType: string; url: string }) => {
    if (state.channel) {
      if (audio) {
        const file = new File(
          [audio.url],
          `${state.channel.externalId}_${
            currentUser?.id
          }_${new Date().getTime()}.webm`,
          {
            type: audio.mimeType?.split(';')[0]
          }
        );
        chatService.sendAttachment(state.channel.id, 'audio', textInput, file);
      } else {
        chatService.sendMessage(state.channel.id, 'plaintext', textInput);
      }
      setTextInput('');
      if (inputRef.current) inputRef.current.focus();
    }
  };
  useEffect(() => {
    chatService
      .getCurrentUser()
      .then((user: ChatUser) => setCurrentUser(user))
      .catch(() => {
        setErrorMessage('Unauthorized');
      });
  }, [chatService]);

  useEffect(() => {
    chatService.triggerUserTyping(textInput.length > 0, props.channelId);
  }, [textInput]);

  useEffect(() => {
    chatService.triggerUserRecordingVoice(isRecording, props.channelId);
  }, [isRecording]);

  useEffect(() => {
    if (scrollBottom) {
      if (newUnreadMessagesRef.current && !scrolledToUnread) {
        newUnreadMessagesRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      } else if (bottomDivRef.current) {
        bottomDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    }
  }, [state.messages, scrollBottom]);

  if (errorMessage) return <div>{errorMessage}</div>;
  if (!currentUser) return <div>{I18n.t('loading')}</div>;
  let sender = {} as Sender;

  return (
    <Fragment>
      {state.channel &&
        (() => {
          const channel = state.channel;
          sender = ChannelService.nameAndImage(channel);
          const { name, image } = sender;

          return (
            <div className='bg-white sm:bg-coolGray-100 p-4 flex items-center justify-between'>
              <div className='flex items-center space-s-4 overflow-hidden'>
                <div className='block sm:hidden'>
                  <NavLink
                    to={`/chat${window.location.search}`}
                    title='chat list'
                    className='text-coolGray-900 w-10 h-10 rounded-lg bg-coolGray-50 flex items-center justify-center'
                  >
                    {localization.isRtl() ? (
                      <RiArrowRightLine size={24} />
                    ) : (
                      <RiArrowLeftLine size={24} />
                    )}
                  </NavLink>
                </div>
                <div className='flex space-s-4 overflow-hidden'>
                  <div className='relative flex-shrink-0'>
                    <img
                      src={channel.photoUrl || image}
                      className='w-10 h-10 object-cover rounded-full'
                      alt={name}
                    />
                    <img
                      src={image}
                      className='absolute top-5 start-5 w-6 h-6 object-cover border-2 border-white rounded-full'
                      alt={name}
                    />
                  </div>
                  <div className='flex-1 overflow-hidden'>
                    <h2
                      title={channel.name}
                      className='mb-0.5 text-sm leading-5 text-coolGray-700 font-semibold truncate'
                    >
                      {channel.name}
                    </h2>
                    <h2
                      title={name}
                      className='text-coolGray-700 leading-4 text-xs mb-1 truncate'
                    >
                      {name}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='relative'>
                <button
                  type='button'
                  className='px-2 py-1 h-full font-medium text-green-900 focus:outline-none'
                  id='channel-options-menu'
                  aria-haspopup='true'
                  aria-expanded='true'
                  onClick={() => setChannelOptionsDropDownVisible(true)}
                >
                  <RiMore2Line size={24} className='text-coolGray-400' />
                </button>
                <div
                  className={
                    'absolute z-10 end-0 top-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ' +
                    (!channelOptionsDropDownVisible ? 'hidden' : '')
                  }
                >
                  <div
                    className='py-1'
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='channel-options-menu'
                  >
                    <DropDownButton
                      title={
                        channel.isArchived
                          ? I18n.t('restore')
                          : I18n.t('archive')
                      }
                      onClick={() => {
                        if (channel.isArchived) {
                          chatService.unArchiveChannel(channel.id);
                        } else {
                          chatService.archiveChannel(channel.id);
                        }
                        setChannelOptionsDropDownVisible(false);
                      }}
                    />
                    <DropDownButton
                      title={I18n.t('cancel')}
                      onClick={() => {
                        setChannelOptionsDropDownVisible(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })()}
      <div
        className='flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded'
        onScroll={(e: any) => {
          if (scrolledToUnread) {
            const bottom =
              e.target.scrollHeight - e.target.scrollTop ===
              e.target.clientHeight;
            setScrollBottom(bottom);
            if (bottom && !state.loadingLater && !state.liveUpdate) {
              loadLater();
            }
          } else {
            const rect = newUnreadMessagesRef.current?.getBoundingClientRect();
            if (rect && rect.height + rect.top > 0) {
              setScrolledToUnread(true);
            }
          }
        }}
      >
        {state.loadingEarlier && <span>{I18n.t('loading')}</span>}
        {state.hasMoreEarlier && !state.loadingEarlier && (
          <span onClick={() => loadEarlier()}>Load Earlier</span>
        )}
        <div id='messages'>
          {state.messages.map((message, index) => {
            const previousMessage = state.messages[index - 1];

            const unreadMessageIndicator = ((!!state.lastReadMessageId &&
              message.id?.toString() === state.lastReadMessageId.toString()) ||
              (state.lastReadMessageId === null &&
                !!state.unreadMessageCount &&
                index === 0)) &&
              !!state.unreadMessageCount &&
              state.unreadMessageCount > 0 &&
              index < state.messages.length - 1 && (
                <div className='relative' ref={newUnreadMessagesRef}>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-10/12 mx-auto border-t border-gray-300' />
                  </div>
                  <div className='relative flex justify-center'>
                    <span className='px-2 bg-white'>
                      <span className='px-2 text-sm bg-emerald-50 rounded-full text-emerald-500 font-semibold'>
                        {state.unreadMessageCount} {I18n.t('new_messages')}
                      </span>
                    </span>
                  </div>
                </div>
              );
            const renderUnreadAtTop =
              state.lastReadMessageId === null &&
              !!state.unreadMessageCount &&
              index === 0;
            const isMe =
              currentUser &&
              message.senderId?.toString() === currentUser.id.toString();
            const otherUserMembership = state.channel?.channelMemberships?.find(
              (cm) => cm.userId !== currentUser.id
            );
            return (
              <div key={message.id}>
                <DateSeparator
                  message={message}
                  previousMessage={previousMessage}
                />
                {renderUnreadAtTop && unreadMessageIndicator}
                <MessageView
                  message={message}
                  sender={sender}
                  isMe={isMe}
                  onRetry={(message: ChatMessage) => {
                    chatService.retryMessage(message);
                  }}
                  lastReadMessageId={
                    otherUserMembership?.lastReadMessageId || 0
                  }
                  lastRecievedMessageId={
                    otherUserMembership?.lastRecievedMessageId || 0
                  }
                />
                {!renderUnreadAtTop && unreadMessageIndicator}
              </div>
            );
          })}
        </div>
        {state.loadingLater && <span>{I18n.t('loading_more')}</span>}
        <div ref={bottomDivRef} />
      </div>
      {state.actingUsers?.map((u) => (
        <span key={u.id}>
          {u.name} is {getActingVerb(u.actingType)}...
        </span>
      ))}

      <form
        className=''
        action=''
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {state.channel && (
          <div>
            {state.channel.status === 'open' && (
              <div>
                <div className='border border-coolGray-300 focus-within:border-coolGray-400 rounded-lg mx-4 mb-4'>
                  <textarea
                    placeholder='Enter to send, Shift & Enter to add new line.'
                    dir='auto'
                    ref={inputRef}
                    value={textInput}
                    autoComplete='off'
                    onChange={(event) => {
                      setTextInput(event.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    className='text-base leading-6 w-full border-0 focus:outline-none focus:ring-0 p-4 h-20 resize-none rounded-lg'
                  />

                  {/*text options*/}
                  <div className='px-4 pb-2 pt-1 flex flex-row-reverse space-s-reverse space-s-4 items-center'>
                    {/*attachment*/}
                    <div className='relative text-start flex items-center justify-center'>
                      <button
                        type='button'
                        className='focus:outline-none'
                        id='options-menu'
                        aria-haspopup='true'
                        aria-expanded='true'
                        onClick={() => setAttachmentDropDownVisible(true)}
                      >
                        <ImAttachment className='text-coolGray-400' size={24} />
                      </button>
                      <div
                        className={
                          'origin-bottom-right absolute z-10 end-0 bottom-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ' +
                          (!attachmentDropDownVisible ? 'hidden' : '')
                        }
                      >
                        <div
                          className='py-1'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='options-menu'
                        >
                          <input
                            ref={fileInputRef}
                            id='imageUpload'
                            type='file'
                            style={{ display: 'none' }}
                            accept='image/png, image/jpeg'
                            onChange={(event) => {
                              if (event && event.target && event.target.files) {
                                if (state.channel) {
                                  chatService.sendAttachment(
                                    state.channel.id,
                                    'image',
                                    textInput,
                                    event.target.files[0]
                                  );
                                  setTextInput('');
                                }
                              }
                            }}
                          />
                          <DropDownButton
                            title={I18n.t('image')}
                            onClick={() => {
                              fileInputRef.current?.click();
                              setAttachmentDropDownVisible(false);
                            }}
                          />
                          {/*<DropDownButton*/}
                          {/*  title='Document'*/}
                          {/*  onClick={() => null}*/}
                          {/*/>*/}
                          {/*<DropDownButton title='File' onClick={() => null} />*/}
                          <DropDownButton
                            title={I18n.t('cancel')}
                            onClick={() => {
                              setAttachmentDropDownVisible(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/*mic recorder*/}
                    {isRecording ? (
                      <div className='flex text-coolGray-400'>
                        <MdCancel
                          onClick={() => {
                            setRecordingCancelled(true);
                            stopRecording();
                          }}
                          className='cursor-pointer'
                          size={24}
                        />
                        <span className='px-2 text-center'>
                          <Timer />
                        </span>
                        <MdCheck
                          onClick={() => {
                            stopRecording();
                          }}
                          className='cursor-pointer'
                          size={24}
                        />
                      </div>
                    ) : (
                      <MdMic
                        onClick={() => {
                          startRecording();
                        }}
                        size={24}
                        className='cursor-pointer text-coolGray-400'
                      />
                    )}
                  </div>
                </div>
                {/* <div className="flex items-center px-4 bg-red-500 text-gray-50 p-1 disabled:bg-green-300 rounded mx-1 pointer">
                  <MdBlock
                    onClick={() => {
                      const otherUserId = state.channel?.channelMemberships
                        ?.filter((cm) => cm.userId !== currentUser.id)
                        .pop()?.userId;
                      if (otherUserId) {
                        chatService.blockUser(otherUserId);
                      }
                    }}
                    size={24}
                  />
                </div>
                <div className="flex items-center px-4 bg-red-500 text-gray-50 p-1 disabled:bg-green-300 rounded mx-1 pointer">
                  <MdCancel
                    onClick={() => {
                      const otherUserId = state.channel?.channelMemberships
                        ?.filter((cm) => cm.userId !== currentUser.id)
                        .pop()?.userId;
                      if (otherUserId) {
                        chatService.unBlockUser(otherUserId);
                      }
                    }}
                    size={24}
                  />
                </div> */}
              </div>
            )}
            {state.channel.status === 'close' && (
              <div className='flex flex-row bg-green-400 p-1'>
                <p>The channel is closed</p>
              </div>
            )}
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default ChannelView;
