import React from 'react';
import LocalizationService from '../services/LocalizationService';

export type AppContextType = {
  userToken: string;
  localization: LocalizationService;
};

export default React.createContext<AppContextType>({
  userToken: '',
  localization: LocalizationService.initialize()
});
